<template>
  <div class="announcement_warp">
    <van-overlay :show="show" :lock-scroll="false">
      <div class="announcement_box">
          <div class="announcementTitle">系统提示</div>
        <div class="content">
          <p class="link">APP线路检测失败</p>
          <p style="margin-top: 12px">解决方案：</p>
          <p>1.请检测您的网络是否正常，是否打开VPN;</p>
          <p>2.关闭APP后十分钟再重试;</p>
          <p>3.前往官网地址<span class="link">yjdm.cc</span>下载最新版本</p>
          <p>4.前往官网TG/土豆交流群反馈问题.</p>
<!--          <p>5.备用网站:<span class="link">https://yjdm.me</span></p>-->
        </div>
          <div class="linkBtn">
              <!-- <div class="selectBtn" @click="open">
                  <span>切换线路</span>
              </div> -->
              <div class="btn" @click="open">
                  <span>前往群聊</span>
              </div>
          </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import {Overlay} from "vant";
import { compatibleAppStoreJump } from '@/utils';
export default {
    props:{
        show: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            content:""
        }
    },
  components: {
    [Overlay.name]: Overlay,
  },
  created() {
  },
  methods: {
      open(){
        compatibleAppStoreJump("https://t.me/yaojingdm1")
      }
  }
}
</script>
<style lang="scss" scoped>
.announcement_warp {
    /deep/ .van-overlay{
        z-index: 33;
    }

    .link{
        color: #333333;
    }

  .announcement_box {
    width: 300px;
    height: 361px;
      //background: rgb(27,22,76);
    // background-image: url("../../assets/png/announcement_bg.png");
    //   background-color: rgb(230,230,230);
    // background-size: 100%;
    //   background-repeat: no-repeat;
    //background-image: linear-gradient(to bottom, rgba(229, 254, 255, 1),rgba(255, 255, 255, 1));
    background: #fff;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
      border-radius: 10px;
      color: #333333;

      .announcementTitle{
        font-size: 20px;
        color: #333333;
        text-align: center;
        margin-top: 18px;
        font-weight: 600;
        padding-bottom: 18px;
        border-bottom: solid 1px rgb(230,230,230);
      }

    .content {
      font-size: 12px;
      margin-top: 18px;
      padding-left: 20px;
      padding-right: 20px;
      line-height: 20px;
      color: #333333;
      height: 220px;
      overflow-y: auto;
      white-space: pre-wrap;
      -webkit-overflow-scrolling: touch;

        p{
            margin-top: 6px;
        }
    }

      .content::-webkit-scrollbar {
          display: block;
          width: 10px;
          height: 10px;
          border-radius: 10px;
      }
      .content::-webkit-scrollbar-thumb {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
          border-radius: 10px;
          background: rgb(39,248,217);
          //background-image: -webkit-gradient(linear,left bottom,left top,color-stop(0.44,rgb(60,186,146)),color-stop(0.72,rgb(253,187,45)),color-stop(0.86,rgb(253,187,45)));
          transition: 0.3s ease-in-out;
      }
      .content::-webkit-scrollbar-track {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
          background-color: #363351;
      }

      .content::-webkit-scrollbar-track {
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
          background-color: #363351;
      }

    .linkBtn {
        display: flex;
        justify-content: center;
        color: #ffffff;
        font-size: 14px;
        text-align: center;
        // .selectBtn {
        //   width: 108px;
        //   height: 38px;
        //   line-height: 38px;
        //   background: #B2B2B2;
        //   border-radius: 19px;
        //   font-weight: 400;
        //   margin-right: 12px;
        // }
        .btn{
            width: 224px;
            height: 38px;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color: rgba(176, 159, 217, 1);
            font-weight: 900;
            box-shadow: 5px 5px 20px 0px rgba(174, 174, 192, 0.5);
            border-radius: 19px;
            margin-right: 8px;
        }
    }
  }

  .close {
    position: absolute;
    font-size: 34px;
    bottom: -80px;
    left: 50%;
    margin-left: -17px;
  }
}

</style>

