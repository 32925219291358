<template>
  <div class="vipPopup">
    <van-overlay :show="show" @click.stop="close">
      <!-- VIP 弹窗样式1 -->
      <div class="vipContent1" v-if="state == 1">
        <div>
          <div class="bgImg">
            <div class="openBtn" @click="openVip">
              <!-- <img @click="openVip" src="@/assets/png/vipPopOpen.png" /> -->
              <div class="vipTitle">温馨提示</div>
            </div>
            <div class="vipNum">
              <span>您还不是会员&nbsp;无法继续</span>
            </div>
            <div class="Continue">
              <span>开通会员&nbsp;即可解锁继续查看</span>
            </div>
            <div class="vipBtn">
              <div class="textBtn" @click.stop="vipClose">
                <span>邀请好友</span>
              </div>
              <div class="openBtn" @click.stop="openVip">
                <span>开通会员</span>
              </div>
            </div>
            <div
              class="ticket"
              @click.stop="volumeUnlock"
              v-if="couponNum && !hideCoupon"
            >
              <span>使用观阅券({{ (couponNum || 0) | watchCount }})</span>
            </div>
          </div>
          <!-- <div class="closeBtn" @click="close">
            <img src="@/assets/png/vipPopBtn.png" />
          </div> -->
        </div>
      </div>
      <!-- 金豆购买 弹窗样式1 -->
      <div class="vipContent3" v-if="state == 2">
        <div>
          <div class="bgImg">
            <div class="unlockGolden">
              <!-- <img @click="openGolden" src="@/assets/png/unlockGolden.png" /> -->
              <div class="goldTitle">金豆解锁</div>
            </div>
            <div class="goldenNum">
              <span
                >您只需花费&nbsp;<span class="golds">{{ goldenNum }}</span
                >&nbsp;金豆</span
              >
            </div>
            <div class="Continue">
              <span>即可继续查看</span>
            </div>
            <div class="goldBtn">
              <div class="textBtn" @click="close">
                <span>稍后再说</span>
              </div>
              <div class="openBtn">
                <!-- <img @click="openGolden" src="@/assets/png/goldenOpen.png" /> -->
                <span @click="openGolden">立即支付</span>
              </div>
            </div>

            <div class="ticket" @click.stop="volumeUnlock()" v-if="couponNum">
              使用观阅券({{ (couponNum || 0) | watchCount }})
            </div>
          </div>
          <!-- <div class="closeBtn" @click="close">
            <img src="@/assets/png/vipPopBtn.png" />
          </div> -->
        </div>
      </div>
      <!-- 金豆购买 弹窗样式4 -->
      <div class="vipContent4" v-if="state == 4">
        <div class="ne_release_popup">
          <div class="closeBtn" @click="close">
            <img src="@/assets/png/vipClose.png" />
          </div>
          <div class="popup_hader">
            <ImgDecypt
              :src="videoInfo.verticalCover"
              :key="videoInfo.verticalCover"
              class="hader_img_mh"
            />
            <div class="hader_right">
              <div class="right_title">
                {{ videoInfo.title }}
              </div>
              <div class="right_type">
                <div class="type_item">第{{ videoInfo.totalEpisode }}集</div>
              </div>
            </div>
          </div>
          <div
            class="popup_info"
            v-if="videoInfo.sellType === 1 || videoInfo.sellType === 0"
          >
            <div class="info_left">本集价格</div>
            <div class="info_right">
              <svg-icon class="goldenBeans" icon-class="gold2Icon"></svg-icon>
              <span>{{ currentEpisode.price }}金币</span>
            </div>
          </div>
          <div class="popup_info" v-if="videoInfo.sellType === 1">
            <div class="info_left">全集价格</div>
            <div class="info_right">
              <svg-icon class="goldenBeans" icon-class="gold2Icon"></svg-icon>
              <span>{{ videoInfo.price }}金币</span>
            </div>
          </div>
          <!--        <div class="info_line" />-->
          <div class="popup_info">
            <div class="info_left">我的金豆</div>
            <div class="info_right">
              <svg-icon class="goldenBeans" icon-class="gold2Icon"></svg-icon>
              <span>{{ walletInfo.amount || 0 }}金币</span>
            </div>
          </div>
          <div class="popup_btn" v-if="videoInfo.sellType === 1">
            <div class="popup_btn_es" @click="openGolden">全集购买</div>
            <div class="popup_btn_es" @click="openGolden(1)">单集购买</div>
          </div>
          <div class="popup_btn" v-else>
            <div class="popup_btn_alone" @click="openGolden(1)">单集购买</div>
          </div>
        </div>
      </div>
      <!-- 弹窗样式5 -->
      <div class="vipContent5" v-if="state == 5">
        <div>
          <div class="bgImg">
            <div class="unlockGolden">
              <!-- <img @click="openGolden" src="@/assets/png/unlockGolden.png" /> -->
              <div class="goldTitle">温馨提示</div>
            </div>
            <div class="goldenNum">
              {{
                activityDetail.status == 2 || activityDetail.status == 3
                  ? "当前免费观影次数已用完！"
                  : "开通限时预售 全站视频免费观看"
              }}
            </div>
            <div class="Continue">
              <span>{{
                activityDetail.status == 2 || activityDetail.status == 3
                  ? "付尾款 享受完整超级会员特权"
                  : "查看更多会员特权"
              }}</span>
            </div>
            <div class="goldBtn">
              <div class="openBtn" v-if="popType == 'vip'">
                <span @click="openVip">会员中心</span>
              </div>
              <div class="openBtn" v-else>
                <span @click="openGolden(popType == 'acg' ? 1 : undefined)"
                  >{{ goldenNum }}金豆支付</span
                >
              </div>
              <div class="textBtn" @click="jumpActivity">
                <span>前往活动</span>
              </div>
            </div>
            <div class="bottomBox">
              <div class="ticketBox">
                <div
                  class="ticket"
                  @click.stop="volumeUnlock()"
                  v-if="couponNum"
                >
                  使用观阅券({{ (couponNum || 0) | watchCount }})
                </div>
              </div>

              <div
                class="preTime"
                :class="{ hasTick: couponNum }"
                v-if="
                  (activityDetail.status == 2 || activityDetail.status == 3) &&
                  newDate < endDate
                "
              >
                <div class="startTime" v-if="newDate < startDate">
                  {{ handleStartDate() }}
                </div>
                <div class="endTime" v-else>仅剩 {{ timeStr }}</div>
              </div>
            </div>
          </div>
          <!-- <div class="closeBtn" @click="close">
            <img src="@/assets/png/vipPopBtn.png" />
          </div> -->
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { queryRights } from "@/api/user";
import { goldExchange } from "@/api/mine";
import ImgDecypt from "@/components/ImgDecypt";
import { Toast } from "vant";
import { mapGetters } from "vuex";

export default {
  name: "VipPopup",
  components: {
    ImgDecypt,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    hideCoupon: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Number,
      default: 1,
    },
    goldenNum: {
      type: Number,
      default: 0,
    },
    activityDetail: {
      type: Object,
    },
    popType: {
      type: String,
      default: "",
    },
    id: {
      type: String,
    },
    videoType: {
      type: Number,
      default: 1,
    },
    currentEpisode: {
      type: Object,
    },
    videoInfo: {
      type: Object,
    },
  },
  data() {
    return {
      couponNum: 0, // 观阅券数量
      couponList: [],
      info: this.videoInfo,
      newDate: "",
      startDate: "",
      timeStr: "",
      countdownSec: 0,
      countdownFncs: "",
    };
  },
  watch: {
    show(newVal, oldVal) {
      if (newVal) {
        this.queryRights();
        if (this.state == 5 && this.activityDetail) {
          this.newDate = new Date().getTime();
          this.startDate = new Date(this.activityDetail.startTime).getTime();
          this.endDate = new Date(this.activityDetail.endTime).getTime();
          // if (
          //   (this.activityDetail.status == 2 ||
          //     this.activityDetail.status == 3) &&
          //   this.newDate > this.startDate
          // ) {
          this.countdownSec = (this.endDate - this.newDate) / 1000;
          // console.log(this.countdownSec);
          this.countdown(this.countdownSec);
          // }
        }
      } else {
        if (this.countdownFncs) {
          clearTimeout(this.countdownFncs);
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      userInfo: "userInfo",
      walletInfo: "walletInfo",
    }),
  },
  mounted() {
    window.addEventListener("popstate", this.handlePopstate);
  },
  beforeDestroy() {
    window.removeEventListener("popstate", this.handlePopstate);
  },
  created() {
    // console.log(23123123)
    this.queryRights();
  },
  methods: {
    // 监听 popstate 事件
    handlePopstate() {
      if (!this.$emit("close")) {
        this.close();
      }
    },
    jumpActivity() {
      this.$router.push("/presale");
    },
    vipClose() {
      this.$emit("close");
      this.$router.push("sharePromotion");
    },
    close() {
      this.$emit("close");
    },
    openVip() {
      this.$emit("openVip");
      this.$router.push("/rechargePage?t=vip");
    },
    handleStartDate() {
      const dateago = new Date(this.startDate);
      return (
        // dateago.getFullYear() +
        // "年" +
        dateago.getMonth() + 1 + "月" + dateago.getDate() + "日 限时开启"
      );
    },
    // 倒计时
    countdown(timer) {
      // console.log(timer);
      if (timer <= 0) return;
      // 相差的毫秒数
      const msec = timer;
      // 计算天时分秒数
      let day = parseInt(msec / 60 / 60 / 24);
      let hr = parseInt((msec / 60 / 60) % 24);
      let min = parseInt((msec / 60) % 60);
      let sec = parseInt(msec % 60);
      // 个位数前补零
      hr = hr > 9 ? hr : "0" + hr;
      min = min > 9 ? min : "0" + min;
      sec = sec > 9 ? sec : "0" + sec;
      this.timeStr = `${day}天${hr}:${min}:${sec}`;
      // 控制台打印
      // console.log(` ${hr}小时 ${min}分钟 ${sec}秒`)
      // 一秒后递归
      this.countdownFncs = setTimeout(() => {
        timer--;
        this.countdown(timer);
      }, 1000);
    },
    // 查询权益
    async queryRights() {
      let res = await this.$Api(queryRights);
      if (res && res.code == 200) {
        this.couponList = res.data.watchReadCoupon || [];
        this.couponNum = this.couponList.length;
      }
    },
    async openGolden(val) {
      /**
       * 金币兑换作品
       */
      let req = {
        chapterID: val === 1 ? this.currentEpisode.id : "",
        productType: this.videoType,
        productID: this.id,
        isH5: true,
      };
      // this.$emit("buySuccess");
      this.$emit("openGolden");
      try {
        await this.$store.dispatch("user/getWallet");
        let walletInfo = this.$store.getters.walletInfo;
        let walletBalance = (
          (walletInfo.amount || 0) + (walletInfo.income || 0)
        ).toFixed(2);
        let price =
          val === 1
            ? this.currentEpisode.price
            : this.goldenNum || this.videoInfo.price;
        if (price > walletBalance) {
          this.$emit("close");
          Toast("金豆不足请充值");
          this.$router.push("/rechargePage?t=gold");
          return;
        }
        this.$store.commit("app/SET_LOADING", true);
        let ret = await this.$Api(goldExchange, req);
        if (ret && ret.code === 200) {
          this.$store.dispatch("user/getUserInfo");
          // 单集购买
          if (val === 1) {
            this.$store.commit("movies/SET_BUY_STATUS", val);
          } else {
            this.$store.commit("movies/SET_BUY_STATUS", null);
          }
          Toast("购买成功");
          this.$emit("open");
          this.$emit("buySuccess", val);
          this.isRecharge = false;
        } else if (ret.code == 8000) {
          this.$emit("close");
          this.$router.push("/rechargePage?t=gold");
        } else if (ret.tip) {
          Toast(ret.tip || "购买失败，请稍后再试");
        }
        this.$store.commit("app/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("app/SET_LOADING", false);
      }
    },
    async volumeUnlock() {
      let req = {
        chapterID: this.currentEpisode.id,
        productType: this.videoType,
        productID: this.currentEpisode.mediaID || this.id,
        couponId: this.couponList[0]?.id,
        isH5: true,
      };
      //   console.log(req, "--------------");
      // this.$emit("buySuccess");
      this.$emit("openGolden");
      try {
        this.$store.commit("app/SET_LOADING", true);
        let ret = await this.$Api(goldExchange, req);

        if (ret && ret.code === 200) {
          this.$store.dispatch("user/getUserInfo");
          // updateStatus
          // 0 // 默认已完结
          // 1 // 更新中
          // 2 // 已完结
          // 单集购买
          this.$store.commit("movies/SET_BUY_STATUS", 1);
          // if ((val === 0 || val === 2) && this.videoInfo.contents.length < 1) {
          //  this.$store.commit('movies/SET_BUY_STATUS', null);
          //  console.log(val, this.videoInfo.contents.length, '全解锁');
          // } else {
          //  this.$store.commit('movies/SET_BUY_STATUS', 1);
          //  console.log(val, this.videoInfo.contents.length, '单解锁');
          // }
          Toast("解锁成功");
          if (this.couponList.length === 1) {
            this.couponNum = 0;
          }
          // await this.queryRights();
          this.$emit("open");
          this.$emit("buySuccess", 1);
          this.isRecharge = false;
        } else if (ret.tip) {
          Toast(ret.tip || "解锁失败，请稍后再试");
        }
        this.$store.commit("app/SET_LOADING", false);
      } catch (error) {
        this.$store.commit("app/SET_LOADING", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vipPopup {
  /deep/ .van-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 3333;
  }

  .vipContent1 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .bgImg {
      width: 300px;
      height: auto;
      //width: 100%;
      //height: 100%;
      // background-image: url("../../assets/png/vipPop.png");
      // background-repeat: no-repeat;
      // background-size: 100% 100%;
      //background-image: linear-gradient(to bottom, rgba(229, 254, 255, 1),rgba(255, 255, 255, 1));
      background: #fff;
      border-radius: 10px;

      .openBtn {
        // padding-top: 288.2px;
        // display: flex;
        // justify-content: center;

        img {
          width: 148px;
          height: 47px;
        }
        .vipTitle {
          font-size: 20px;
          color: #333333;
          text-align: center;
          padding-top: 18px;
          font-weight: 600;
          padding-bottom: 18px;
          border-bottom: solid 1px rgb(230, 230, 230);
        }
      }

      .vipNum {
        margin-top: 20px;
        text-align: center;
        font-weight: bolder;
        font-size: 20px;
        color: #333333;
      }

      .Continue {
        margin-top: 20px;
        font-size: 14px;
        color: #333333;
        font-weight: 400;
        text-align: center;
      }

      .vipBtn {
        margin-top: 25px;
        display: flex;
        justify-content: center;
        text-align: center;
        color: #ffffff;
        font-size: 14px;
        font-weight: bolder;
        line-height: 38px;
        padding-bottom: 18px;
        .openBtn {
          width: 136px;
          height: 38px;
          background: #fff;
          font-size: 14px;
          color: rgba(176, 159, 217, 1);
          font-weight: 900;
          box-shadow: 5px 5px 20px 0px rgba(174, 174, 192, 0.5);
          border-radius: 19px;
        }

        .textBtn {
          width: 108px;
          height: 38px;
          background: #fff;
          font-size: 14px;
          color: rgba(176, 159, 217, 1);
          font-weight: 900;
          box-shadow: 5px 5px 20px 0px rgba(174, 174, 192, 0.5);
          border-radius: 19px;
          margin-right: 12px;
        }
      }
      .ticket {
        text-align: center;
        font-weight: 400;
        color: #333333;
        font-size: 12px;
        padding-bottom: 18px;
      }
    }

    .closeBtn {
      display: flex;
      justify-content: center;
      margin-top: 44px;

      img {
        width: 10px;
        height: 10px;
      }
    }
  }

  .vipContent3 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .bgImg {
      width: 300px;
      height: auto;
      //width: 100%;
      //height: 100%;
      // background-image: url("../../assets/png/goldenPop.png");
      // background-repeat: no-repeat;
      // background-size: 100% 100%;
      //background-image: linear-gradient(to bottom, rgba(229, 254, 255, 1),rgba(255, 255, 255, 1));
      background: #fff;
      border-radius: 10px;
      .unlockGolden {
        // padding-top: 238.2px;
        // display: flex;
        // justify-content: center;
        .goldTitle {
          font-size: 20px;
          color: #333333;
          text-align: center;
          padding-top: 18px;
          font-weight: 600;
          padding-bottom: 18px;
          border-bottom: solid 1px rgb(230, 230, 230);
        }

        img {
          width: 203px;
          height: 35px;
        }
      }

      .goldenNum {
        margin-top: 20px;
        // width: 38px;
        text-align: center;
        // position: absolute;
        // margin-left: 145px;
        // margin-top: -26.2px;
        font-weight: 500;
        font-size: 20px;
        color: #333333;
        .golds {
          font-weight: bolder;
          color: #fc4162;
          font-size: 28px;
        }
      }

      .Continue {
        margin-top: 20px;
        font-size: 14px;
        color: #333333;
        font-weight: 400;
        text-align: center;
      }

      .goldBtn {
        margin-top: 25px;
        display: flex;
        justify-content: center;
        text-align: center;
        color: #ffffff;
        font-size: 14px;
        font-weight: bolder;
        line-height: 38px;
        padding-bottom: 18px;
        .openBtn {
          width: 136px;
          height: 38px;
          background: #fff;
          font-size: 14px;
          color: rgba(176, 159, 217, 1);
          font-weight: 900;
          box-shadow: 5px 5px 20px 0px rgba(174, 174, 192, 0.5);
          border-radius: 19px;
          // padding-top: 15px;
          // display: flex;
          // justify-content: center;

          img {
            width: 148px;
            height: 47px;
          }
        }

        .textBtn {
          width: 108px;
          height: 38px;
          background: #fff;
          font-size: 14px;
          color: rgba(176, 159, 217, 1);
          font-weight: 900;
          box-shadow: 5px 5px 20px 0px rgba(174, 174, 192, 0.5);
          border-radius: 19px;
          margin-right: 8px;
          // display: flex;
          // justify-content: center;
          // padding-top: 9px;
        }
      }
      .ticket {
        text-align: center;
        font-weight: 400;
        color: #333333;
        font-size: 12px;
        padding-bottom: 18px;
      }
    }

    .closeBtn {
      display: flex;
      justify-content: center;
      margin-top: 44px;

      img {
        width: 31px;
        height: 31px;
      }
    }
  }

  .vipContent4 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    .ne_release_popup {
      width: 100%;
      height: 433px;
      background: linear-gradient(180deg, #f5f3ff 0%, #ffffff 100%);
      padding: 16px;
      border-radius: 18px 18px 0px 0px;
      .closeBtn {
        display: flex;
        justify-content: flex-start;
        margin-top: 6px;
        margin-bottom: 20px;

        img {
          width: 18px;
          height: 18px;
        }
      }
      .popup_hader {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        .hader_img_mh {
          width: 77px;
          height: 112px;
          /deep/ .van-image__img {
            border-radius: 8px;
          }
        }
        .hader_right {
          flex: 1;
          margin-left: 8px;
          .right_title {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 10px;
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .right_type {
            display: flex;
            color: #000;
            font-size: 12px;
            .type_item {
              color: #777777;
              display: flex;
              align-items: center;
              margin-right: 14px;
              .type_img {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
      }
      .popup_info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        margin-bottom: 20px;
        letter-spacing: 0.5px;
        .info_left {
          color: #000;
          font-weight: 600;
        }
        .info_right_zs {
          font-weight: bold;
          color: #6954e7;
          display: flex;
          align-items: center;
          .right_zs_icon {
            width: 18px;
            height: 18px;
            margin-right: 6px;
          }
        }
        .info_right {
          display: flex;
          align-items: center;
          font-weight: bold;
          color: #f2990e;
          .goldenBeans {
            width: 20px;
            height: 20px;
          }
          span {
            padding-left: 8px;
          }
        }
        .info_right_es {
          text-decoration: line-through;
        }
      }
      .info_line {
        height: 1px;
        background: #e2e2e5;
        margin-bottom: 20px;
      }
      .popup_btn {
        margin-top: 38px;
        display: flex;
        justify-content: center;
        .popup_btn_es {
          width: 47%;
          text-align: center;
          height: 56px;
          background: #f0f0f3;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          color: rgba(176, 159, 217, 1);
          font-weight: 900;
        }
        .popup_btn_es:first-child {
          border-radius: 22px 0 0 22px;
          box-shadow: 5px 0 20px 0 rgba(174, 174, 192, 0.5);
          border-right: 2px solid #c3c9cd;
        }
        .popup_btn_es:last-child {
          border-radius: 0 22px 22px 0;
          box-shadow: 5px 0 20px 0 rgba(174, 174, 192, 0.5);
        }
        .popup_btn_alone {
          width: 333px;
          height: 56px;
          border-radius: 22px;
          background: #f0f0f3;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          color: rgba(176, 159, 217, 1);
          font-weight: 900;
          box-shadow: 5px 5px 20px 0px rgba(174, 174, 192, 0.5);
        }
      }
    }
  }
  .vipContent5 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .bgImg {
      width: 300px;
      height: auto;
      //width: 100%;
      //height: 100%;
      // background-image: url("../../assets/png/goldenPop.png");
      // background-repeat: no-repeat;
      // background-size: 100% 100%;
      //background-image: linear-gradient(to bottom, rgba(229, 254, 255, 1),rgba(255, 255, 255, 1));
      background: #fff;
      border-radius: 10px;
      .unlockGolden {
        // padding-top: 238.2px;
        // display: flex;
        // justify-content: center;
        .goldTitle {
          font-size: 20px;
          color: #333333;
          text-align: center;
          padding-top: 18px;
          font-weight: 600;
          padding-bottom: 18px;
          border-bottom: solid 1px rgb(230, 230, 230);
        }

        img {
          width: 203px;
          height: 35px;
        }
      }

      .goldenNum {
        margin-top: 20px;
        // width: 38px;
        text-align: center;
        // position: absolute;
        // margin-left: 145px;
        // margin-top: -26.2px;
        font-weight: 400;
        font-size: 16px;
        color: #666;
        .golds {
          font-weight: bolder;
          color: #fc4162;
          font-size: 28px;
        }
      }

      .Continue {
        margin-top: 12px;
        font-size: 16px;
        color: #666;
        font-weight: 400;
        text-align: center;
      }

      .goldBtn {
        margin-top: 25px;
        display: flex;
        justify-content: center;
        text-align: center;
        color: #ffffff;
        font-size: 14px;
        font-weight: bolder;
        line-height: 38px;
        padding-bottom: 18px;
        .openBtn {
          width: 120px;
          height: 38px;
          background: #fff;
          font-size: 14px;
          color: rgba(176, 159, 217, 1);
          font-weight: 900;
          box-shadow: 5px 5px 20px 0px rgba(174, 174, 192, 0.5);
          border-radius: 19px;
          margin-right: 12px;
          // padding-top: 15px;
          // display: flex;
          // justify-content: center;

          img {
            width: 148px;
            height: 47px;
          }
        }

        .textBtn {
          width: 120px;
          height: 38px;
          background: #fff;
          font-size: 14px;
          color: rgba(176, 159, 217, 1);
          font-weight: 900;
          box-shadow: 5px 5px 20px 0px rgba(174, 174, 192, 0.5);
          border-radius: 19px;
          // margin-right: 8px;
          // display: flex;
          // justify-content: center;
          // padding-top: 9px;
        }
      }
      .bottomBox {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 24px 18px;
        box-sizing: border-box;
        .ticketBox {
          width: 120px;
        }
        .ticket {
          text-align: center;
          font-weight: 400;
          color: #333333;
          font-size: 12px;
          text-align: center;
          // padding: 0 6px;
          // box-sizing: border-box;
          width: 120px;
          // padding-bottom: 18px;
        }
        .preTime {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          width: 120px;
          text-align: center;
          // padding: 0 6px;
          // box-sizing: border-box;
          .startTime {
            color: #0012dd;
          }
          .endTime {
            color: #dd001b;
          }
        }
        .hasTick {
          margin-left: 12px;
        }
      }
    }

    .closeBtn {
      display: flex;
      justify-content: center;
      margin-top: 44px;

      img {
        width: 31px;
        height: 31px;
      }
    }
  }
}
</style>
