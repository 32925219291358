<template>
  <div class="main" @click.prevent>
    <keep-alive :include="include">
      <router-view />
    </keep-alive>
    <TabBar />
    <!-- 预售 + 支付尾款 浮窗 -->
    <div
      class="persalebox"
      v-if="
        appConfig.advanceStatus.activityStatus &&
        activityDetail.status != 4 &&
        $route.path != '/mine'
      "
    >
      <div @click="$router.push('/presale')">
        <ImgDecypt class="persaleImg" :src="persaleBoxBg" :key="persaleBoxBg" />
      </div>

      <!-- <img
        v-if="isShowPersaleFC == 'ys'"
        @click="$router.push('/presale')"
        src="@/assets/png/presale/persalepop.png"
        alt=""
      />
      <img
        v-else-if="isShowPersaleFC == 'pay'"
        @click="$router.push('/presale')"
        src="@/assets/png/presale/paypop.png"
        alt=""
      /> -->
    </div>
    <div
      class="store-bag"
      @click="download"
      v-if="showStoreBag && appConfig.shopIosLink"
    >
      <img
        src="./../assets/png/close1.png"
        @click.stop="showStoreBag = false"
        alt=""
      />
    </div>
  </div>
</template>
<script>
import TabBar from "@/components/Tabbar";
import { getPresaleList } from "@/api/home";
import { judgeTime } from "@/utils/index";
import ImgDecypt from "@/components/ImgDecypt";
import { mapGetters } from "vuex";
export default {
  name: "Main",
  components: {
    TabBar,
    ImgDecypt,
  },
  computed: {
    ...mapGetters({
      preInfo: "preInfo",
    }),
  },
  data() {
    return {
      include: ["VideoModule", "Original", "Home"],
      activityDetail: {},
      appConfig: {},
      isShowPersaleFC: "", //浮窗展示
      showStoreBag: localStorage.getItem("isAppStore") ? false : true,
      persaleBoxBg: "",
    };
  },
  created() {
    this.appConfig = this.$store.getters.appConfig;
    this.getPresaleData();
  },
  methods: {
    handleBg() {
      let nowDate = new Date().getTime();
      let startDate = new Date(this.activityDetail.startTime).getTime();
      if (this.preInfo && this.preInfo.length) {
        this.preInfo.forEach((i) => {
          if (i.type == 2) {
            // console.log(i, "-=-=-");
            if (
              this.activityDetail.balancePayment &&
              i.status == 2 &&
              nowDate > startDate
            ) {
              this.persaleBoxBg = i.url;
            } else if (i.status == 1) {
              this.persaleBoxBg = i.url;
            }
            // if (this.activityDetail.balancePayment) {
            //   if (i.status == 2) {
            //     this.persaleBoxBg = i.url;
            //   } else if (nowDate < startDate && i.status == 3) {
            //     this.persaleBoxBg = i.url;
            //   }
            // } else if (i.status == 1) {
            //   this.persaleBoxBg = i.url;
            // }
          }
        });
      }
    },
    download() {
      window.location.href = this.appConfig.shopIosLink;
    },
    async getPresaleData() {
      let req = {
        pageNumber: 1,
        pageSize: 1,
      };
      let ret = await this.$Api(getPresaleList, req);
      // console.log(ret, "-=--==-==============");
      if (ret && ret.code == 200) {
        this.activityDetail = ret.data.activityDetail;
        this.handleBg();
        // 预售浮窗
        if (
          this.activityDetail.status == 0 ||
          this.activityDetail.status == 1
        ) {
          this.isShowPersaleFC = "ys";
        } else if (
          (this.activityDetail.status == 2 ||
            this.activityDetail.status == 3) &&
          judgeTime(this.activityDetail.startTime)
        ) {
          //支付尾款浮窗
          this.isShowPersaleFC = "pay";
        } else if (
          (this.activityDetail.status == 2 ||
            this.activityDetail.status == 3) &&
          !judgeTime(this.activityDetail.startTime)
        ) {
          //预售
          this.isShowPersaleFC = "ys";
        } else {
          this.isShowPersaleFC = "";
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.main {
  height: calc(100% - 28px);
  //overflow-y: auto;
}
.persalebox {
  position: fixed;
  bottom: 185px;
  right: 18px;
  .persaleImg {
    width: 60px;
    animation: shake 1.5s infinite;
  }
}
.store-bag {
  width: 283px;
  height: 36px;
  background: url("./../assets/png/sotrePack.png") no-repeat;
  background-size: 100% 100%;
  position: fixed;
  bottom: 285px;
  left: 0;
  img {
    width: 20px;
    height: 20px;
    position: absolute;
    top: -5px;
    right: -5px;
  }
}
@keyframes shake {
  0%,
  65% {
    transform: rotate(0deg) scale(1);
  }
  70% {
    transform: rotate(0deg) scale(1.1);
  }
  75% {
    transform: rotate(6deg) scale(1.1);
  }
  80% {
    transform: rotate(-6deg) scale(1.1);
  }
  85% {
    transform: rotate(6deg) scale(1.1);
  }
  90% {
    transform: rotate(-6deg) scale(1.1);
  }
  95% {
    transform: rotate(0deg) scale(1);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}
</style>
