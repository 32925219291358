<template>
  <div id="app" class="pcm-app" @click.prevent>
    <keep-alive :include="keepAliveList">
      <router-view v-if="isRouterAlive" />
    </keep-alive>
    <GlobalLoading v-if="globalLoading" />
    <!-- 打赏 -->
    <RewardPop v-if="rewarDate.show" />
    <!-- 评论 -->
    <CommentPop
      @close="commentPopDate.show = false"
      v-if="commentPopDate.show"
    />
    <!-- VIP 弹窗 -->
    <VipPopup
      :show="vipPopupShow"
      :id="vipPopupId"
      :videoType="videoType"
      :state="vipPopupState"
      :goldenNum="goldenNumPopup"
      :popType="popType"
      :activityDetail="activityDetail"
      :videoInfo="videoInfo"
      :currentEpisode="currentEpisode"
      :hideCoupon="hideCoupon"
      @close="closeVipPopup"
      @openVip="openVipPopup"
      @openGolden="openGoldenPopup"
      @buySuccess="buySuccess"
    />

    <!-- 赚会员分享弹窗 -->
    <SharePopup
      :show="sharePopupShow"
      :postInfo="sharePostInfo"
      @close="closeSharePopup"
      v-if="sharePopupShow"
    />

    <NetlessPop :show="netlessPopShow" />

    <van-popup
      v-model="certificatePop"
      class="certificatePop"
      @close="closeCertificatePop"
    >
      <div class="certificateBox">
        <div class="certificateTitle">账号凭证</div>
        <div class="certificateContent">
          受行业限制，APP无法正常使用时需升级，未绑定手机号码会导致账号信息丢失。请及时绑定手机号码或保存账号凭证，以免VIP信息丢失，造成巨大财产损失！账号丢失的用户可在
          『我的』页面-账号找回，原账号的VIP信息会转移至新账号上。
        </div>
        <div class="bindPhoneBox">
          <div class="bindPhoneText" @click="bindPhone">绑定手机号码&nbsp;</div>
          <svg-icon class="rightAttow" iconClass="settingArrow" />
        </div>
        <!-- <div class="popLine"></div> -->
        <div class="bindBtn">
          <div class="saveBtn" @click="certificatePop = false">我已保存</div>
          <div class="saveBtn" @click="saveCredentials">保存图片</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import GlobalLoading from "@/components/GlobalLoading";
import { mapGetters } from "vuex";
import inobounce from "@/assets/js/inobounce.min";
import RewardPop from "@/components/RewardPop";
import CommentPop from "@/components/CommentPop";
import SharePopup from "@/components/SharePopup";
import { Dialog } from "vant";
import { getDevType } from "@/utils";

import { dataAdd } from "@/api/app";

import VipPopup from "@/components/VipPopup";

import NetlessPop from "@/components/NetlessPop";

import store from "./store";

export default {
  name: "app",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
      vipPopupShow: false, //VIP弹窗展示
      hideCoupon: false, // 是否隐藏观阅券
      sharePopupShow: false, //分享弹窗展示
      sharePostInfo: {},
      vipPopupState: 1, //VIP弹窗样式（1（vip默认）、2（vip带观影卷）、3（金豆购买默认）、4（金豆购买带观影卷））
      goldenNumPopup: 0, //金豆数量,vipPopupState 等于3、4的时候传入，不传入的话会出现 0
      vipPopupId: null, //作品ID，用于作品购买
      popType: "", //弹窗类型
      activityDetail: {}, // 预售详情
      videoInfo: {}, // 作品详情
      currentEpisode: {}, // 单集
      videoType: 0, //动漫17    //漫画18    //cos19    //真人21  //禁播2 // 圈子1
      certificatePop: false, // 账号凭证
      netlessPopShow: false,
      keepPath: {
        "/home": {
          className: "my-refresh",
        },
        "/community": {
          className: "VideoDetailsPage",
        },
        "/publishImg": {
          className: "PublishImg",
        },
        "/bookshelf": {
          className: "Bookshelf",
        },
        "/topicPage": {
          className: "TopicPage",
        },
        "/creationCenter": {
          className: "my-refresh",
        },
        "/tagsDetailPage": {
          className: "my-refresh",
        },
        "/purchased": {
          className: "my-refresh",
        },
        "/categoriesPage": {
          className: "my-refresh",
        },
        "/userHomePage": {
          className: "contentBox",
        },
        "/tagList": {
          className: "my-refresh",
        },
        "/historyRecord": {
          className: "my-refresh",
        },
        "/hotRankingPage": {
          className: "HotRankingPage",
        },
        "/hotTopic": {
          className: "HotTopic",
        },
        "/topicDetails": {
          className: "TopicDetails",
        },
        "/videoModule": {
          className: "my-refresh",
        },
        "/searchResultPage": {
          className: "my-refresh",
        },
      },
    };
  },
  components: {
    RewardPop,
    CommentPop,
    GlobalLoading,
    VipPopup,
    NetlessPop,
    SharePopup,
  },
  computed: {
    ...mapGetters({
      keepPathScroll: "keepPathScroll",
      keepAliveList: "keepAliveList",
      globalLoading: "globalLoading",
      vipDate: "vipDate",
      commentPopDate: "commentPopDate",
      rewarDate: "rewarDate",
    }),
  },
  // created() {
  //   console.log("keepAliveList: ", this.keepAliveList)
  // },
  methods: {
    closeCertificatePop() {
      sessionStorage.setItem("isCertificate", "0");
    },
    bindPhone() {
      this.certificatePop = false;
      this.$router.push("/bindPhone");
    },
    saveCredentials() {
      this.certificatePop = false;
      this.$router.push("/accountCredentials");
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    closeVipPopup() {
      /**
       * 关闭 VIP充值弹窗
       * @type {boolean}
       */
      this.vipPopupShow = false;
    },
    openVipPopup() {
      /**
       * 点击VIP充值
       * @type {boolean}
       */
      this.vipPopupShow = false;
    },
    openGoldenPopup() {
      /**
       * 点击金豆充值
       */
    },
    buySuccess() {
      /**
       * 金豆解锁成功
       */
    },
    closeSharePopup() {
      /**
       * 关闭分享弹窗
       * @type {boolean}
       */
      this.sharePopupShow = false;
    },
  },
  mounted() {
    //google 不支持此方法，加了后页面所以滚动将被禁止
    if (
      getDevType() == "ios" &&
      /Safari/.test(navigator.userAgent) &&
      !/Chrome/.test(navigator.userAgent)
    ) {
      inobounce.enable();
    }
    // 检测断网
    window.addEventListener("offline", () => {
      // Dialog.alert({
      //   title: "提示",
      //   message: "网络已断开",
      //   confirmButtonText: "我已知晓",
      //   theme: "round-button",
      // });
      this.$bus.$emit("netlessPop");
    });
    // 检测网络已连接
    window.addEventListener("online", () => {
      // Dialog.alert({
      //   title: "提示",
      //   message: "网络已连接",
      //   confirmButtonText: "我已知晓",
      //   theme: "round-button",
      // });
      this.$bus.$emit("netlessPop");
    });

    let _this = this;

    this.$bus.$on("netlessPop", (data) => {
      _this.netlessPopShow = true;
    });

    /**
     * 打开分享弹窗
     */
    this.$bus.$on("openSharePopup", (postInfo) => {
      _this.sharePostInfo = postInfo;
      _this.sharePopupShow = true;
    });

    /**
     * 注册VIP弹窗事件
     */
    this.$bus.$on("vipPopup", (data) => {
      // console.log(data, 'data-----------------------------')
      _this.vipPopupShow = true;
      if (data.hideCoupon) {
        this.hideCoupon = data.hideCoupon;
      }
      /**
       * 关闭按钮
       */
      if (data["closeBtn"]) {
        _this.closeVipPopup = data["closeBtn"];
      }
      // if(data['openBtn']){
      //     _this.closeVipPopup = data['openBtn'];
      // }

      /**
       * 开通VIP按钮
       */
      if (data["openVipBtn"]) {
        _this.openVipPopup = data["openVipBtn"];
      }

      /**
       * 金币开通按钮
       */
      if (data["openGoldenBtn"]) {
        _this.openGoldenPopup = data["openGoldenBtn"];
      }

      /**
       * 金币购买成功按钮
       */
      if (data["buySuccess"]) {
        _this.buySuccess = data["buySuccess"];
      }

      /**
       * 弹窗样式
       *  1: VIP
       *  2: 金豆
       */
      if (data["state"]) {
        _this.vipPopupState = data["state"];
      }
      /**
       * 弹窗类型
       *  vip: vip
       *  acgPrice: acg金豆
       *  coins: 视频金豆
       */
      if (data["popType"]) {
        _this.popType = data["popType"];
      }

      /**
       * 预售信息
       */
      if (data["activityDetail"]) {
        _this.activityDetail = data["activityDetail"];
      }
      /**
       * 金币购买数量（样式未金币购买时需传入）
       */
      if (data["goldenNum"]) {
        _this.goldenNumPopup = data["goldenNum"];
      }

      /**
       * 作品ID
       */
      if (data["id"]) {
        _this.vipPopupId = data["id"];
      }

      /**
       * 作品详情
       */
      if (data["videoInfo"]) {
        _this.videoInfo = data["videoInfo"];
      }

      /**
       * 单集详情
       */
      if (data["currentEpisode"]) {
        _this.currentEpisode = data["currentEpisode"];
      }

      /**
       * 作品类型
       */
      if (data["videoType"] != undefined) {
        //动漫17  //漫画、小说18  //cos19  //真人21  //禁播22 //写真24 //圈子1
        switch (Number(data["videoType"])) {
          case 0:
            _this.videoType = 28;
            break;
          case 1:
            _this.videoType = 28;
            break;
          case 2:
            _this.videoType = 28;
            break;
          case 3:
            _this.videoType = 28;
            break;
          case 4:
            _this.videoType = 28;
            break;
          case 5:
            _this.videoType = 28;
            break;
          case 6:
            _this.videoType = 28;
            break;
          case 7:
            _this.videoType = 28;
            break;
          case 8:
            _this.videoType = 28;
            break;
          default:
            _this.videoType = 1;
            break;
        }
      } else {
        _this.videoType = 1;
      }
    });

    /**
     * 数据埋点
     */
    this.$bus.$on("dataBuriedEvent", function (data) {
      dataAdd(data);
    });
    /**
     * 关闭vip弹窗
     */
    this.$bus.$on("closeVipPopup", () => {
      _this.vipPopupShow = false;
    });
  },
  watch: {
    $route(to, from) {
      if (to.path == "/mine") {
        this.certificatePop =
          sessionStorage.getItem("isCertificate") == 0 ? false : true;
      }

      try {
        if (this.keepPathScroll[from.path]) {
          const className = this.keepPathScroll[from.path]?.className;
          // 查找到className才储存
          if (className) {
            const domArr = document.getElementsByClassName(className);
            //单个页面
            if (domArr.length === 1) {
              const dom = domArr[0];
              const scrollTop = dom?.scrollTop;
              if (dom) {
                store.commit("app/SET_UPDATE_SCROLL", {
                  key: from.path,
                  sonKey: "scrollTop",
                  sonValue: scrollTop,
                });
              }
            } else {
              //同个路由多个存在tab
              if (this.keepPathScroll[from.path]?.isTab) {
                let curIndex = this.keepPathScroll[from.path].curIndex;
                console.log(curIndex);
                const dom = domArr[curIndex];
                const scrollTop = dom?.scrollTop;
                store.commit("app/SET_UPDATE_SCROLL", {
                  key: from.path,
                  sonKey: "scrollArr",
                  sonValue: scrollTop,
                  keyIndex: curIndex,
                });
              }
            }
          }
        }
        //  设置
        if (this.keepPathScroll[to.path]) {
          const className = this.keepPathScroll[to.path]?.className;
          if (className) {
            this.$nextTick(() => {
              const domArr = document.getElementsByClassName(className);
              //单个页面
              if (domArr.length === 1) {
                const domIndex = domArr.length - 1;
                const dom = domArr[domIndex];
                const scrollTop = this.keepPathScroll[to.path].scrollTop;
                if (dom) {
                  dom.scrollTop = scrollTop;
                }
              } else {
                //同个路由多个存在tab
                if (this.keepPathScroll[to.path]?.isTab) {
                  let curIndex = this.keepPathScroll[to.path].curIndex;
                  const dom = domArr[curIndex];
                  const scrollTop =
                    this.keepPathScroll[to.path].scrollArr[curIndex];
                  if (dom && scrollTop > 0) {
                    dom.scrollTop = scrollTop;
                  }
                }
              }
            });
          }
        }
      } catch (e) {
        console.log(e);
      }

      // //保存
      // if (this.keepPath[from.path]) {
      //   let className = this.keepPath[from.path]?.className;
      //   // console.log("className: ", className)
      //   // 查找到className才储存
      //   if (className) {
      //     let domArr = document.getElementsByClassName(className);
      //     let domIndex = domArr.length - 1;
      //     let dom = domArr[domIndex];
      //     let scrollTop = dom?.scrollTop;
      //     if (dom) {
      //       from.meta.scrollTop = scrollTop;
      //       // console.log("scrollTop: ", from.meta.scrollTop)
      //     }
      //   }
      // }
      // //  设置
      // if (this.keepPath[to.path]) {
      //   let className = this.keepPath[to.path]?.className;
      //   if (className) {
      //     this.$nextTick(() => {
      //       let domArr = document.getElementsByClassName(className);
      //       let domIndex = domArr.length - 1;
      //       let dom = domArr[domIndex];
      //       let scrollTop = to.meta?.scrollTop;
      //       if (dom) {
      //         dom.scrollTop = scrollTop;
      //       }
      //     });
      //   }
      // }
    },
  },
  beforeDestroy() {
    inobounce.disable();
  },
};
</script>
<style lang="scss">
#app {
  font-size: 14px;
  height: 100%;
  background: $bg;
  position: relative;
  color: #000000;
}

.pcm-app {
  width: 100%;
  max-width: 540px;
  margin: auto;
  position: relative;
  background: $bg;
  height: 100%;
  overflow: hidden;
  min-height: 100%;
  padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  .van-tabbar--fixed {
    position: fixed;
    bottom: 0;
    left: auto;
    max-width: 540px;
  }
}

/* 左滑进左滑出 右滑进 右滑出 */
.slide-l-enter-active,
.slide-l-leave-active {
  transition: all 0.3s linear;
  transform: translateX(0);
}

.slide-l-enter,
.slide-l-leave {
  transform: translateX(100%);
}

.slide-l-leave-to {
  transform: translateX(100%);
}

.slide-r-enter-active,
.slide-r-leave-active {
  transition: all 0.3s linear;
  transform: translateX(0);
}

.slide-r-enter,
.slide-r-leave {
  transform: translateX(-100%);
}

.slide-r-leave-to {
  transform: translateX(-100%);
}

/* 路由切换左滑进左滑出 右滑进 右滑出 */
.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
  will-change: transform;
  transition: all 0.4s;
}

.slide-right-enter {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.slide-right-leave-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.slide-left-enter {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.slide-left-leave-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.certificatePop {
  border-radius: 9px;
  color: #333333;
  // background-image: linear-gradient(to bottom, rgba(229, 254, 255, 1),rgba(255, 255, 255, 1));
  .certificateBox {
    width: 300px;
    height: 361px;
    // padding: 0 18px;
    // box-sizing: border-box;
    .certificateTitle {
      font-size: 20px;
      color: #333333;
      text-align: center;
      margin-top: 18px;
      font-weight: 600;
      padding-bottom: 18px;
      border-bottom: solid 1px rgb(230, 230, 230);
    }
    .certificateContent {
      font-size: 12px;
      line-height: 32px;
      margin: 18px 18px 18px;
    }
    .bindPhoneBox {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 13px;
      .bindPhoneText {
        font-size: 12px;
        color: #333333;
      }
    }
    .popLine {
      width: 100%;
      height: 1px;
      background: #e6e6e6;
    }
    .bindBtn {
      position: absolute;
      bottom: 10px;
      left: 50%;
      transform: translate(-50%, -50%);
      // margin: 30px 16px 0 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      .saved {
        color: #ffffff;
        width: 108px;
        height: 38px;
        text-align: center;
        border-radius: 19px;
        line-height: 38px;
        background: #b2b2b2;
        margin-right: 16px;
      }
      .saveBtn {
        width: 124px;
        height: 37px;
        text-align: center;
        border-radius: 19px;
        line-height: 37px;
        color: #b09fd9;
        font-family: "Dream Han Sans TC";
        font-size: 14px;
        font-weight: bolder;
        border-radius: 30px;
        background: #ffffff;
        box-shadow: -5px -5px 20px 0px #fff,
          5px 5px 20px 0px rgba(174, 174, 192, 0.5);
        margin-right: 8px;
      }
      .saveBtn:last-child {
        margin-right: 8px;
      }
    }
  }
}
</style>
